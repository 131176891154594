export const config = {
    /* ====== Dashboard Links ===== */
    app_dashboard: "/app-dashboard",
    seller_link: "/seller",
    seller_details: "/seller-details",
    seller_properties: "/seller-properties",
    seller_fact_find: "/seller-fact-find",
    property_details: "/property-details",
    photo_gallery: "/photo-gallery",
    additional_notes: "/additional-notes",
    //create_presentation: "/create-presentation",
    create_preval_presentation: "/create-preval-presentation",
    create_custom_presentation: "/create-custom-presentation",
    valuation_day: "/valuation-day",
    presentation_preview: "/presentation-preview",
    search_link: "/search",


    /* ====== Preval Presentation Links ===== */
    preval_present: "/preval-presentation",
    preval_casestudies: "/preval-presentation/case-studies",
    preval_people: "/preval-presentation/people",
    preval_marketing: "/preval-presentation/marketing",
    preval_marketing_analytics: "/preval-presentation/marketing-analytics",
    preval_360_tours: "/preval-presentation/360-tours",
    preval_digital_domination: "/preval-presentation/digital-domination",
    preval_your_home: "/preval-presentation/your-home",
    preval_statistics_area: "/preval-presentation/statistics-area",
    preval_valuation_day: "/preval-presentation/valuation-day",
    preval_contact_us: "/preval-presentation/contact-us",


    /* ====== Custom Presentation Links ===== */
    custom_present: "/custom-presentation",
    custom_casestudies: "/custom-presentation/case-studies",
    custom_campaign_timeline: "/custom-presentation/campaign-timeline",
    custom_prop_datetime: "/custom-presentation/property-date-time",
    custom_schedule: "/custom-presentation/schedule",
    custom_360_photographer: "/custom-presentation/360-photographer",
    custom_teams: "/custom-presentation/teams",
    custom_launch: "/custom-presentation/launch",
    custom_advance_buyer: "/custom-presentation/advance-buyer",
    custom_marketing: "/custom-presentation/marketing",
    custom_marketing_analytics: "/custom-presentation/marketing-analytics",
    custom_360_tours: "/custom-presentation/360-tours",
    custom_digital_domination: "/custom-presentation/digital-domination",
    custom_campaign_report: "/custom-presentation/campaign-report",
    custom_plan: "/custom-presentation/plan",
    custom_comparables: "/custom-presentation/comparables",
    custom_fees: "/custom-presentation/fees",
    custom_compare_cost: "/custom-presentation/compare-cost",
    custom_sell_home: "/custom-presentation/sell-home",
    custom_contract_form: "/custom-presentation/contract-form",
    custom_contact_us: "/custom-presentation/contact-us",
    custom_present_success: "/custom-presentation/presentation-success",
    custom_marketing_campaign: "/custom-presentation/marketing-campaign",
}

export const paymentFees = {
    former_client: 250,
    VAT: 594,
    arranged_mortgage: 300,
    arranged_conveyancer: 0,

    DocSignUrl:"https://na2.docusign.net/Member/PowerFormSigning.aspx",
    No_Sale_No_Fee: "8aed4b55-7d62-4ec2-b156-a8401b792e70",
    Halfway_House: "5b5857fb-beab-469f-b271-168b8b855f96",
    Move_in_2: "0300145c-0ce1-4f36-a2d6-7f9c25c01cbd",
    acct: "0d095a93-c630-4834-8ec8-2854a16d1974",
    v: 2,
    env: "na2"
}