import React, {useState} from "react"
import {Container,Row,Col,Button,Form} from 'react-bootstrap';
import { Link, navigate } from "gatsby";
import LeftNav from "../components/Common/leftNav";
import SEO from "../components/seo"

import InputField from '../components/forms/elements/input'
import SelectField from '../components/forms/elements/select'
import ButtonField from '../components/forms/elements/button'

import '../components/forms/assets/styles/_index.scss'

const CreatePresentation = (props) => {

  const fields = ([
    {
      element: "config",
      formname: "Custom_Presentation",
      form_type: "Custom_Presentation",
      error_text: "Highlighted fields are required | invalid",
      success_text: "",
      email_temp_user: "simple_user",
      email_temp_admin: "simple_admin",
      email_server_func: "contact",
      event_tracking: "contact",
      page_url: "/create-custom-presentation"
    },
    {
      grpmd: "12",
      label: "Customer:",
      placeholder: "",
      name: "customer_name",
      element: "select",
      required: true,
      values: ['Mr A & Mrs D Smith', 'Mrs A. Ali', 'Mrs T. Armitt-Smith', 'Ms A. Smith', 'Mr P. Jenkinson'],
      labelClass: "content_b-18",
      class: "white_bg"
    },
    {
      grpmd: "12",
      label: "Property Price:",
      placeholder: "",
      name: "property_price",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[0-9)]+(\\s+[0-9]+)*$",
      labelClass: "content_b-18",
      inputText: "£",
      class: "no_bg col-11"
    },    
    {
      grpmd: "12",
      name: "SUBMIT",
      type:"submit",
      element: "button",
      value: "create presentation",
      class: "btn-primary",
      labelClass: "content_b-18"
    }
]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  return(

    <>
      <SEO title="CreatePresentation" description="CreatePresentation" />

      <div className="dashboard-wrapper">
        <LeftNav page_name="Create Presentation" />
        {/* sidebar */}

        <div className="dashboard-content">
        <Container>
            <div className="content-wrapper seller-wrapper">
              <div className="seller-section">
                <div className="seller-top">
                  <h2>Create Custom Presentation <span className="brdr_btm"></span></h2>
                </div>
                <Row>
                <Col xs={12} md={12}>
                    <div className="card-block cust_present">
                      <Form className="present-form stbform" action="javascript:;" method="post">
                        <Form.Row>
                        {
                          fields.map((field, index) => {

                            if ( "input" === field.element ) {
                              return (
                                <InputField
                                  name={field.name}
                                  grpmd={field.grpmd}
                                  label={field.label}
                                  ref={field.ref}
                                  type={field.type}
                                  fieldClass={field.class}
                                  placeholder={field.placeholder}
                                  labelClass={field.labelClass}
                                  required={field.required}
                                  key={`${field.element}~${index}`}
                                  pattern={field.patternchk}
                                  inputText={field.inputText}
                                  handlechange={handlechange}
                                />
                              );
                            }
                            if ("select" === field.element) {
                              return (
                                <SelectField
                                  name={field.name}
                                  grpmd={field.grpmd}
                                  label={field.label}
                                  ref={field.ref}
                                  required={field.required}
                                  fieldClass={field.class}
                                  placeholder={field.placeholder}
                                  values={field.values}
                                  key={`${field.element}~${index}`}
                                  handlechange={handlechange}
                                  componentprops={props}
                                />
                              );
                            }
                            if ("button" === field.element) {
                              return (
                                <>
                                <ButtonField
                                  name={field.name}
                                  fieldClass={field.class}
                                  type={field.type}
                                  value={field.value}
                                  key={`${field.element}~${index}`}
                                  grpmd={field.grpmd}
                                />
                                
                                </>
                              );
                            }

                          })
                        }
                        </Form.Row>
                      </Form>
                    </div>
                </Col>
                </Row>
              </div>
            </div>
        </Container>
        {/* container */}
      </div>
      {/* dashboard-content */}
      </div>
    </>
  )

}

export default CreatePresentation